/*
 * TODO: remove all typeof's and check all useEffect's
 */ 
import React, { useEffect, useRef } from 'react';

import { createChart } from 'lightweight-charts';

const addSeriesFunctions = {
  candlestick: 'addCandlestickSeries',
  line: 'addLineSeries',
  area: 'addAreaSeries',
  bar: 'addBarSeries',
  histogram: 'addHistogramSeries',
};

const colors = [
  '#008FFB',
  '#00E396',
  '#FEB019',
  '#FF4560',
  '#775DD0',
  '#F86624',
  '#A5978B',
];

const darkTheme = {
  layout: {
    backgroundColor: '#131722',
    lineColor: '#2B2B43',
    textColor: '#D9D9D9',
  },
  grid: {
    vertLines: {
      color: '#363c4e',
    },
    horzLines: {
      color: '#363c4e',
    },
  },
};

const lightTheme = {
  layout: {
    backgroundColor: '#11FFFF',
    lineColor: '#2B2B43',
    textColor: '#191919',
  },
  grid: {
    vertLines: {
      color: '#e1ecf2',
    },
    horzLines: {
      color: '#e1ecf2',
    },
  },
};

const ChartWrapper = ({
  options,
  darkTheme,
  from,
  to,
  candlestickSeries,
  lineSeries,
  areaSeries,
  barSeries,
  histogramSeries,
  autoHeight,
  height,
  autoWidth,
  width,
  legend,
  onCrosshairMove,
  onClick,
  onTimeRangeMove 
}) => {
  const chartDiv = useRef(null);
  const legendDiv = useRef(null);

  const ref = useRef({
    chart: null,
    series: [],
    legends: [],
  });

  const handleEvents = () => {
    onClick && ref.current.chart.subscribeClick(onClick);
    onCrosshairMove &&
      ref.current.chart.subscribeCrosshairMove(onCrosshairMove);
    onTimeRangeMove &&
      ref.current.chart.subscribeVisibleTimeRangeChange(onTimeRangeMove);

    // handle legend dynamical change
    ref.current.chart.subscribeCrosshairMove(handleLegends);
  };

  const handleTimeRange = () => {
    from && to && ref.current.chart.timeScale().setVisibleRange({ from, to });
  };

  const handleLinearInterpolation = (data, candleTime) => {
    if (!candleTime || data.length < 2 || !data[0].value) return data;
    let first = data[0].time;
    let last = data[data.length - 1].time;
    let newData = new Array(Math.floor((last - first) / candleTime));
    newData[0] = data[0];
    let index = 1;
    for (let i = 1; i < data.length; i++) {
      newData[index++] = data[i];
      let prevTime = data[i - 1].time;
      let prevValue = data[i - 1].value;
      let { time, value } = data[i];
      for (
        let interTime = prevTime;
        interTime < time - candleTime;
        interTime += candleTime
      ) {
        // interValue get from the Taylor-Young formula
        let interValue =
          prevValue +
          (interTime - prevTime) * ((value - prevValue) / (time - prevTime));
        newData[index++] = { time: interTime, value: interValue };
      }
    }
    // return only the valid values
    return newData.filter((x) => x);
  };

  const resizeHandler = () => {
    const prevDisplayVal = chartDiv.current.style.display;
    chartDiv.current.style.display = 'none';
    const lWidth = autoWidth && chartDiv.current.parentNode.clientWidth;
    const lHeight =
      autoHeight && chartDiv.current.parentNode
        ? chartDiv.current.parentNode.clientHeight
        : height || 200;
    chartDiv.current.style.display = prevDisplayVal;
    ref.current.chart.resize(lWidth, lHeight);
  };
  
  const handleUpdateChart = () => {
    if (!ref.current.chart) return;
    const prevDisplayVal = chartDiv.current.style.display;
    chartDiv.current.style.display = 'none';
    window.removeEventListener('resize', resizeHandler);
    let lOptions = darkTheme ? darkTheme : lightTheme;
    lOptions = mergeDeep(lOptions, {
      width: autoWidth
        ? chartDiv.current.parentNode.clientWidth
        : width,
      height: autoHeight
        ? chartDiv.current.parentNode.clientHeight
        : height || 500,
      ...options,
    });
    chartDiv.current.style.display = prevDisplayVal;
    ref.current.chart.applyOptions(lOptions);
    if (legendDiv.current) legendDiv.current.innerHTML = '';
    ref.current.legends = [];
    if (legend) handleMainLegend();

    if (autoWidth || autoHeight) {
      // resize the chart with the window
      window.addEventListener('resize', resizeHandler);
    }
  };

  const addLegend = (series, color, title) => {
    ref.current.legends.push({ series, color, title });
  };

  const handleLegends = (param) => {
    let div = legendDiv.current;
    if (param.time && div && ref.current.legends.length) {
      div.innerHTML = '';
      ref.current.legends.forEach(({ series, color, title }) => {
        let price = param.seriesPrices.get(series);
        if (typeof price == 'object') {
          color =
            price.open < price.close
              ? 'rgba(0, 150, 136, 0.8)'
              : 'rgba(255,82,82, 0.8)';
          price = `O: ${price.open}, H: ${price.high}, L: ${price.low}, C: ${price.close}`;
          let row = document.createElement('div');
          row.innerText = title + ' ';
          let priceElem = document.createElement('span');
          priceElem.style.color = color;
          priceElem.innerText = ' ' + price;
          row.appendChild(priceElem);
          div.appendChild(row);
        }
      });
    }
  };

  const handleMainLegend = () => {
    if (legendDiv.current) {
      let row = document.createElement('div');
      row.innerText = legend;
      legendDiv.current.appendChild(row);
    }
  };

  useEffect(() => {
    ref.current.chart = createChart(chartDiv.current);
    handleSeries();
    handleEvents();
    
    return () => {
      ref.current.chart = null;
    };
  }, []);

  useEffect(() => {
    unsubscribeEvents(); // was prevProps
  }, [onCrosshairMove, onTimeRangeMove, onClick]);

  useEffect(() => {
    return () => {
      if (autoWidth && autoHeight) {
        window.removeEventListener('resize', resizeHandler);
      }
    }
  }, [autoWidth, autoHeight]);

  useEffect(() => {
    removeSeries();
    handleUpdateChart();
  }, [
    options,
    darkTheme,
    candlestickSeries,
    lineSeries,
    areaSeries,
    barSeries,
    histogramSeries,
  ]);

  useEffect(() => {
    handleTimeRange();
  }, [from, to]);

  const removeSeries = () => {
    if (typeof ref.current.series.forEach == 'function') {
      ref.current.series.forEach((serie) =>
        ref.current.chart.removeSeries(serie)
      );
      ref.current.series = [];
    }
  };

  const addSeries = (serie, type) => {
    const func = addSeriesFunctions[type];
    let color =
      (serie.option && serie.options.color) ||
      colors[ref.current.series.length % colors.length];
    ref.current.series = ref.current.chart[func]({
      color,
      ...serie.options,
    });
    let data = handleLinearInterpolation(serie.data, serie.linearInterpolation);
    ref.current.series.setData(data);
    if (serie.markers) ref.current.series.setMarkers(serie.markers);
    if (serie.priceLines)
      serie.priceLines.forEach((line) =>
        ref.current.series.createPriceLine(line)
      );
    if (serie.legend) addLegend(ref.current.series, color, serie.legend);
    return ref.current.series;
  };

  const handleSeries = () => {
    candlestickSeries &&
      candlestickSeries.forEach((serie) => {
        ref.current.series.push(addSeries(serie, 'candlestick'));
      });

    lineSeries &&
      lineSeries.forEach((serie) => {
        ref.current.series.push(addSeries(serie, 'line'));
      });

    areaSeries &&
      areaSeries.forEach((serie) => {
        ref.current.series.push(addSeries(serie, 'area'));
      });

    barSeries &&
      barSeries.forEach((serie) => {
        ref.current.series.push(addSeries(serie, 'bar'));
      });

    histogramSeries &&
      histogramSeries.forEach((serie) => {
        ref.current.series.push(addSeries(serie, 'histogram'));
      });
  };

  const unsubscribeEvents = () => {
    ref.current.chart.unsubscribeClick(onClick);
    ref.current.chart.unsubscribeCrosshairMove(onCrosshairMove);
    typeof ref.current.chart.unsubscribeVisibleTimeRangeChange == 'function' &&
      ref.current.chart.unsubscribeVisibleTimeRangeChange(
        onTimeRangeMove
      );
  };

  let color = darkTheme
    ? darkTheme.layout.textColor
    : lightTheme.layout.textColor;

  return (
    <div ref={chartDiv} style={{ position: 'relative' }}>
      <div
        ref={legendDiv}
        style={{
          position: 'absolute',
          zIndex: 2,
          color,
          padding: 10,
        }}
      />
    </div>
  );
};

const isObject = (item) =>
  item && typeof item === 'object' && !Array.isArray(item);

const mergeDeep = (target, source) => {
  let output = Object.assign({}, target);
  if (isObject(target) && isObject(source)) {
    Object.keys(source).forEach((key) => {
      if (isObject(source[key])) {
        if (!(key in target)) Object.assign(output, { [key]: source[key] });
        else output[key] = mergeDeep(target[key], source[key]);
      } else {
        Object.assign(output, { [key]: source[key] });
      }
    });
  }
  return output;
};

export default ChartWrapper;
