import React, { useContext } from "react";
import { useAuthState } from "Context/User";

import css from "./LoggedIndicator.module.scss";

const LoggedIndicator = () => {
  const userDetails = useAuthState();

  if (userDetails?.token) {
    return (
      <section className={css.loginIndicatorWrapper}>
        <a href="/login">
          <div className={css.loginIndicatorIcon} src="/login" />
          <span className={css.loginIndicatorEmail}>{userDetails.user}</span>
        </a>
      </section>
    );
  } else {
    return <a className={css.doLogIn} href="/login">Sign in</a>;
  }
};

export default LoggedIndicator;
