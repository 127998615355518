import React from "react";
import styled from "styled-components";

const AlertSettingsStyled = styled.div`
  background: #fafafa;
  border-radius: 10px;
  border: 1px solid #ebebeb;
  display: block;
  width: 100%;
  margin: 0 0 10px;
  box-sizing: border-box;
  padding: 6px;
`;

const AlertSettings = ({ open, ...props }) => {
  const isHidden = open ? true : false;

  return (
    <AlertSettingsStyled open={open} aria-hidden={!isHidden} {...props}>
      <strong>Alert Name here</strong><br/>
      When <br/>
      <input type="text" size='4' placeholder='40' /> &nbsp;
      days MA <br/>
      <strong>CROSSES</strong> <br/>
      <input type="text"  size='4' placeholder='3' /> &nbsp;
      days MA 
    </AlertSettingsStyled>
  );
};

export default AlertSettings;
