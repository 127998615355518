// @flow

import Http from 'Api/Http';
import * as constants from 'src/Constants';

const API_HOST: string = process.env.REACT_APP_API_HOST;

const apiV1 = new Http();

type ITypeCredentials = { email: string, user: string, token: string, password: string };

// curl --header "Content-Type: application/json" http://maingain-api.local/api/v1/sign_in --request POST --data
//   $'{ "user": { "email":"abcc@example.com", "password":"asdfasdf" }}'
async function login(credentials: ITypeCredentials) {
  const response = await apiV1.post(`${API_HOST}/api/v1/sign_in`, {
    user: {
      email: credentials.email,
      password: credentials.password,
    },
  });

  return response;
}

async function getPortfolio(credentials: ITypeCredentials) {
  const response = await apiV1.get(
    `${API_HOST}/api/v1/historicals.json` +
      `?size=40&user_email=${credentials.user}&user_token=${credentials.token}`
  );

  return response;
}

// +verb+: add | remove
async function saveTickers(
  tickers: $ReadOnlySet<string>,
  credentials: ITypeCredentials,
  verb: string
) {
  const TICKERS_ADD_URL = `${constants.API_HOST}/api/v1/tickers/${verb}`;
  const suffix = `?user_email=${credentials.user}&user_token=${credentials.token}`;
  const url = `${TICKERS_ADD_URL}${suffix}`;

  await apiV1
    .post(url, {
      tickers: Array.from(tickers),
    })
    .then((response) => {
      if (response.ok) {
        return response.json();
      }
      throw new Error('Fetched with error.');
    })
    .catch((err) => {
      console.log(err.message);
    });
}

export default {
  apiV1, // @TODO: check why this doesn't export
  login,
  getPortfolio,
  saveTickers,
};
