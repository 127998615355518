import React from "react";

import styles from "./List.module.scss";

const ListItemHeader = () => {
  return (
    <div key='header' className={styles["list-item-header"]}>
      <div
        className={`${styles["list-item-header__section"]} ${styles["list-item-header__section--ticker"]}`}
      >
        Ticker
      </div>

      <div
        className={`${styles["list-item-header__section"]} ${styles["list-item-header__section--price"]}`}
      >
        Price
      </div>

      <div
        className={`${styles["list-item-header__section"]} ${styles["list-item-header__section--chart"]}`}
      >
        10 days
      </div>
    </div>
  );
};

export default ListItemHeader;
