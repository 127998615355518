// @flow

import { merge } from 'lodash';

const DEFAULT_OPTS = {};

class Http {
  options: {};

  constructor(opts: any) {
    this.options = merge({}, DEFAULT_OPTS, opts);
  }

  async post(url: string, params: any) {
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(params)
    };

    const response = await fetch(url, requestOptions);
    const data = await response.json();
    return data;
  }

  async get(url: string) {
    const requestOptions = {
      method: 'GET',
    };

    const response = await fetch(url, requestOptions);
    const data = await response.json();
    return data;
  }
}

export default Http;
