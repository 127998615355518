import React from "react";

import pageStyles from "./PageLayout.module.scss";

export function Privacy() {
  return (
    <>
      <h1>Privacy Policy</h1> 

      <h2> Introduction</h2>

      <p>
        Thank you for visiting our Service
        <a href='https://entheog.com'>
          https://entheog.com
        </a>
        also a part of the mainGain app!
      </p>

      <p>
        If you have a registered account, you will have your own EntheoG user email account (User Email). By using your User Email, you can
        authenticate to our app and create your own lists, such as stock porfolio lists. Your User Email is used solely for providing you with
        the data of your interest.
      </p>

      <h2> Commitment</h2>
      <p>
        We are committed to protecting your online privacy by recognizing and respecting your need for appropriate management of any personal information.
      </p>

      <h2>Your Consent</h2>
      <p>
        By using this Service, you consent to the terms of our Privacy Policy and to EntheoG's processing of Personal Information for the purposes given above.
        If you do not agree to this Privacy Policy, please do not use our Service.
      </p>
    </>
  );
}
