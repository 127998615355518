import React, { useState, useReducer } from 'react';

const lsCurrentUser = localStorage.getItem('currentUser');
let user,
  token,
  signedWith;
if (lsCurrentUser) {
  user = JSON.parse(lsCurrentUser).user;
  token = JSON.parse(lsCurrentUser).token;
  signedWith = JSON.parse(lsCurrentUser).signedWith;
}

export const initialState = {
  user: user || '',
  token: token || '',
  signedWith: signedWith || '',
  loading: false,
  errorMessage: null,
};

export const AuthReducer = (initialState, action) => {
  switch (action.type) {
    case 'REQUEST_LOGIN':
      return {
        ...initialState,
        loading: true,
      };
    case 'LOGIN_SUCCESS':
      return {
        ...initialState,
        user: action.payload.user,
        token: action.payload.token,
        signedWith: action.payload.signedWith,
        loading: false,
      };
    case 'LOGOUT':
      return {
        ...initialState,
        user: '',
        token: '',
        signedWith: '',
      };

    case 'LOGIN_ERROR':
      return {
        ...initialState,
        loading: false,
        errorMessage: action.error,
      };

    default:
      throw new Error(`Unhandled action type: ${action.type}`);
  }
};
