import React from 'react';

import css from './Button.module.scss';

const Button = ({ addClasses, onClick, children }) => {
  addClasses = addClasses?.split(' ').map((clsName) => css[clsName]).join(' ') || '';
  
  return <button onClick={onClick} className={`${css.button} ${addClasses}`}>{children}</button>;
};

export default Button;
