import styled from "styled-components";

export const StyledBurger = styled.button`
  position: absolute;
  top: 4px;
  left: 4px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 2rem;
  height: 2rem;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  z-index: 10;
  background: #373737;

  span {
    width: 2rem;
    height: 0.25rem;
    background: #EFFFFA;
    border-radius: 10px;
    transition: all 0.3s linear;
    position: relative;
    transform-origin: 1px;

    :first-child {
      transform: ${({ open }) =>
        open ? "rotate(38deg); width: 2.4rem;" : "rotate(0);"};
    }

    :nth-child(2) {
      opacity: ${({ open }) => (open ? "0" : "1")};
    }

    :nth-child(3) {
      transform: ${({ open }) =>
        open ? "rotate(-38deg); width: 2.4rem;" : "rotate(0)"};
    }
  }
`;
