import React from "react";

import { ListItem, ListItemHeader } from "Components/List";

const List = ({ items }) => {
  if (items.length === 0) {
    return null;
  }

  return [
    ListItemHeader(),
    items.map((item) => {
      return(
        <ListItem key={item.ticker} companyName={item.companyName} ticker={item.ticker} price={item.price} data={item.data} />
      )
    })
  ];
};

export default List;
