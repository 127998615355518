import React from 'react';

import Link from 'Components/Link';
import { useAuthState } from 'Context/User';

import styles from './Navigation.module.scss';

// This is the router which renders the rest of the pages.
export function Navigation({ handleSetMenuOpen }) {
  const userDetails = useAuthState();

  return (
    <header>
      <ul className={styles.nav}>
        <li className={styles.nav__item}>
          <Link to="/about" onClick={(e) => handleSetMenuOpen(false)}>About</Link>
        </li>
        <li className={styles.nav__item}>
          <Link to="/portfolio" onClick={(e) => handleSetMenuOpen(false)}>Portfolio</Link>
        </li>
        <li className={styles.nav__item}>
          <Link to="/privacy" onClick={(e) => handleSetMenuOpen(false)}>Privacy</Link>
        </li>
        <li className={styles.nav__item}>
          {!userDetails?.token && <Link to="/login" onClick={(e) => handleSetMenuOpen(false)}>Sign in</Link>}
          {userDetails?.token && <Link to="/login" onClick={(e) => handleSetMenuOpen(false)}>Sign out</Link>}
        </li>
      </ul>
    </header>
  );
}
