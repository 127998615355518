import React from "react";
import { useParams } from "react-router";

import AlertSettings from "Components/AlertSettings";

import pageStyles from "./PageLayout.module.scss";

export function TickerAlerts(props) {
  let { ticker } = useParams();

  return (
    <div className={pageStyles.content}>
      <h2>Alerts settings for {ticker}</h2>

      <AlertSettings type='ma' name='Moving Average 40 x 3' />
      <AlertSettings type='ma' name='MACD 20' />
      <AlertSettings type='ma' name='RSA ...' />
    </div>
  );
}
