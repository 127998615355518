import apiV1 from 'Api/v1';

export async function loginGoogleUser(dispatch, userData) {
  try {
    let data = {
      user: userData.loginFormValues.email,
      token: userData.loginFormValues.token_id,
      signedWith: 'google',
    };
    dispatch({ type: 'LOGIN_SUCCESS', payload: data });
    localStorage.setItem('currentUser', JSON.stringify(data));
    return data;
  } catch (error) {
    dispatch({ type: 'LOGIN_ERROR', error: error });
    console.log(error);
  }
}

export async function loginUser(dispatch, loginPayload) {
  try {
    dispatch({ type: 'REQUEST_LOGIN' });
    let credentials = {
      email: loginPayload.loginFormValues.email,
      password: loginPayload.loginFormValues.password,
    };
    let response = await apiV1.login(credentials);
    if (!response.is_success) {
      console.log(response.messages);
    }

    let data = {
      user: response.data.user.email,
      token: response.data.user.authentication_token,
    };

    if (data.user) {
      dispatch({ type: 'LOGIN_SUCCESS', payload: data });
      localStorage.setItem('currentUser', JSON.stringify(data));
      return data;
    }

    dispatch({ type: 'LOGIN_ERROR', error: data.errors[0] });
    console.log(data.errors[0]);
    return;
  } catch (error) {
    dispatch({ type: 'LOGIN_ERROR', error: error });
    console.log(error);
  }
}

export async function logout(dispatch) {
  dispatch({ type: 'LOGOUT' });
  localStorage.removeItem('currentUser');
  localStorage.removeItem('token');
}
