import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Form, Field } from 'react-final-form';

import { loginUser, logout, useAuthState, useAuthDispatch } from 'Context/User';

import Button from 'Components/Button';

import styles from './LoginForm.module.scss';

const LoginForm = () => {
  const history = useHistory();
  const dispatch = useAuthDispatch();
  const userDetails = useAuthState();

  const handleLogout = () => {
    logout(dispatch);
  };

  const onSubmit = async (loginFormValues) => {
    try {
      let response = await loginUser(dispatch, { loginFormValues });
      if (!response.user) return;
      history.push('/portfolio');
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <section className={styles.container}>
      <div className={styles.wrapper}>
        <Form
          onSubmit={onSubmit}
          render={({ handleSubmit, pristine, form, submitting, values }) => {
            return (
              <>
                {!userDetails?.token && (
                  <>
                    <form onSubmit={handleSubmit}>
                      <Field
                        className={styles.input}
                        component="input"
                        name="email"
                        placeholder="name@example.com"
                      />
                      <Field
                        className={styles.input}
                        component="input"
                        name="password"
                        placeholder="Password"
                        type="password"
                      />
                      <Button type="submit">Sign in</Button>
                    </form>
                    <hr className={styles.hr} />
                  </>
                )}
                {userDetails?.token && (
                  <Button onClick={handleLogout}>Sign out</Button>
                )}
              </>
            );
          }}
        />
      </div>
    </section>
  );
};

export default LoginForm;
