import React, { useContext, useState } from 'react';
import { loginGoogleUser, useAuthDispatch, useAuthState } from 'Context/User';
import { useHistory } from 'react-router-dom';

import { GoogleLogin, GoogleLogout } from 'react-google-login';
import * as constants from 'src/Constants';

const LoginWithGoogle = () => {
  const history = useHistory();
  const dispatch = useAuthDispatch();
  const userDetails = useAuthState();

  const clientId =
    '1083812843162-ln53sumeb7g0sepererq3p99unh2v3ie.apps.googleusercontent.com';

  const loginVerifiedGoogleUser = (userData) => {
    try {
      let response = loginGoogleUser(dispatch, { loginFormValues: userData });
      history.push('/portfolio');
    } catch (error) {
      console.log(error);
    }
  };

  const onGoogleLoginSuccess = (response) => {
    const useful_data = {
      google_login: {
        tokenId: response['tokenId'],
      },
    };
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(useful_data),
    };

    return fetch(
      `${constants.API_HOST}/api/v1/google/authorize`,
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.status !== 'signed_in') {
          return;
        }

        loginVerifiedGoogleUser(data);
      });
  };

  if (userDetails.loading) {
    return null;
  }

  return (
    <>
      {!(userDetails?.token) && (
        <GoogleLogin
          clientId={clientId}
          buttonText="Sign in with Google"
          onSuccess={onGoogleLoginSuccess}
          cookiePolicy={'single_host_origin'}
        />
      )}
      {/* Do we need a google logout? */}
      {/* {userDetails?.token  && (
        <GoogleLogout
          clientId={clientId}
          buttonText="Sign out with Google"
          redirectUri={constants.API_HOST}
        />
      )} */}
    </>
  );
};

export default LoginWithGoogle;
