import React from 'react';
import { About } from "Pages/About";
import { Portfolio } from "Pages/Portfolio";
import { Privacy } from "Pages/Privacy";
import { Login } from "Pages/Login";
import { TickerAlerts } from "Pages/TickerAlerts";

const routes = [
	{
		path: ['/portfolio', '/'],
		component: Portfolio,
		needLogin: true,
  },
	{
		path: '/login',
		component: Login,
		needLogin: false,
	},
	{
		path: '/about',
		component: About,
		needLogin: false,
  },
  {
		path: '/portfolio',
		component: Portfolio,
		needLogin: true,
  },
  {
		path: '/alerts/:ticker',
		component: TickerAlerts,
		needLogin: false,
  },
  {
		path: '/privacy',
		component: Privacy,
		needLogin: false,
	},
];

export default routes;
