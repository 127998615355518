import { useEffect } from "react";

export const useOnClickOutside = (refs, handler) => {
  useEffect(() => {
    const listener = (event) => {
      let found = false;

      refs.forEach((ref) => {
        if (ref?.current?.contains(event.target)) {
          found = true;
          return;
        }
      });

      if (found) {
        return;
      }

      handler(event);
    };
    document.addEventListener("mousedown", listener);

    return () => {
      document.removeEventListener("mousedown", listener);
    };
  }, [refs, handler]);
};
