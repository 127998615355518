import React from "react";

import pageStyles from "./PageLayout.module.scss";

export function About() {
  return (
    <div className={pageStyles.content}>
      <h2>About</h2>
    </div>
  );
}
