// @flow
import React, { useRef, useState } from 'react';
import { ThemeProvider } from 'styled-components';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Modal from 'react-modal';

import routes from 'src/config/routes';
import styles from './App.module.scss';
import { theme } from './theme';
import { LoggedIndicator } from 'Components/Auth';

import { Burger, Menu } from 'Components/BurgerMenu';
import FocusLock from 'react-focus-lock';

import { AuthProvider } from 'Context/User';

import { useOnClickOutside } from 'Hooks/useOnClickOutside';

const AppRouter = () => (
  <Switch>
    {routes.map((route) => (
      <Route
        key={route.path}
        exact
        path={route.path}
        component={route.component}
        needLogin={route.needLogin}
      />
    ))}
  </Switch>
);

Modal.setAppElement('#root');

function App() {
  const [burgerOpen, setBurgerOpen] = useState(false);
  const burgerMenuNode = useRef();
  const burgerNode = useRef();
  const menuId = 'burger-menu';

  useOnClickOutside([burgerMenuNode, burgerNode], () => setBurgerOpen(false));

  return (
    <ThemeProvider theme={theme}>
      <AuthProvider>
        <Router>
          <div ref={burgerMenuNode}>
            <FocusLock disabled={!burgerOpen}>
              <Menu open={burgerOpen} handleSetMenuOpen={setBurgerOpen} id={menuId} />
            </FocusLock>
          </div>

          <div className={styles.App}>
            <header className={styles.header}>
              <div ref={burgerNode}>
                <FocusLock disabled={!burgerOpen}>
                  <Burger
                    open={burgerOpen}
                    setOpen={setBurgerOpen}
                    aria-controls={menuId}
                  />
                </FocusLock>
              </div>
              <LoggedIndicator />
            </header>

            <AppRouter />
          </div>
        </Router>
      </AuthProvider>
    </ThemeProvider>
  );
}

export default App;
