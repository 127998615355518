import { Link as RouterLink } from 'react-router-dom';
import styled from 'styled-components';

const Link = styled(RouterLink)`
  color: #f09e09;
  background: #fff;
  font-size: 1em;
  margin: 1em;
  padding: 0.5em 1em;
  text-align: center;
  border: 2px solid #f09e09;
  border-radius: 10px;
  display: block;
  cursor: pointer;
  text-decoration: none;
  outline: none;

  &:hover {
    background: #fafafa;
  }
`;

export default Link;
