import React, { useContext } from "react";
import { LoginForm, LoginWithGoogle } from "Components/Auth";
import { useAuthDispatch, logout, useAuthState } from "Context/User";

import Button from "Components/Button";

import styles from "./Login.module.scss";
import pageStyles from "./PageLayout.module.scss";

export function Login() {
  const dispatch = useAuthDispatch();
  const userDetails = useAuthState();

  const onLogout = async () => {
    logout(dispatch);
  };

  if (!userDetails.user?.token) {
    return (
      <div className={pageStyles.content}>
        <LoginForm />
        <LoginWithGoogle />
      </div>
    );
  } else {
    return (
      <div className={pageStyles.content}>
        <section className={styles.container}>
          User: {userDetails.user.email} is lоgged in.
          <Button onClick={onLogout}>Logout</Button>
        </section>
      </div>
    );
  }
}
