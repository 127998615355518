import React from 'react';
import Chart from 'Components/Chart';

import styles from './List.module.scss';

const chartOptions = {
  topColor: 'rgba(33, 150, 243, 0.56)',
  bottomColor: 'rgba(33, 150, 243, 0.04)',
  layout: { backgroundColor: '#E7E9ED' },
  lineColor: 'rgba(33, 150, 243, 1)',
  lineWidth: 2,
  // rightPriceScale: false,    // uncomment to HIDE the price scale
  timeScale: true,
  crosshair: {
    // need this struct, becuase "crosshain: false" causes warnings
    vertLine: {
      visible: false,
    },
    horzLine: {
      visible: false,
    },
  },
};

var areaSeriesOptions = {
  topColor: '#0984e3',
  bottomColor: '#74b9ff00',
  lineColor: '#74b9ff',
  lineWidth: 2,
};

const ListItem = ({ ticker, companyName, price, data }) => {
  const itemsData = [
    {
      options: areaSeriesOptions,
      data: data.map((data) => {
        return { time: data[0], value: parseFloat(data[1]) };
      }),
    },
  ];

  return (
    <div className={styles['list-item']}>
      <a
        href={`/alerts/${ticker}`}
        className={`${styles['list-item__section']} ${styles['list-item__section--ticker']}`}
      >
        <div className={`${styles['list-item__section--ticker-text']}`}>
          {ticker}
        </div>
        <div className={`${styles['list-item__section--company-name']}`}>
          {companyName}
        </div>
      </a>

      <div
        className={`${styles['list-item__section']} ${styles['list-item__section--price']}`}
      >
        <div className={`${styles['list-item__section--price-text']}`}>
          {Number.parseFloat(price).toFixed(2)}
        </div>
      </div>

      <div
        className={`${styles['list-item__section']} ${styles['list-item__section--chart']}`}
      >
        <Chart
          options={chartOptions}
          areaSeries={itemsData}
          autoWidth
          height={60}
        />
      </div>
    </div>
  );
};

export default ListItem;
