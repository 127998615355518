// @flow

import React, { useState } from "react";

import * as constants from "src/Constants";
import { useAuthState } from "Context/User";
import Button from "Components/Button";
import Input from "Components/Input";

import css from "./TickersSelect.module.scss";

const TICKERS_LIKE_URL = `${constants.API_HOST}/api/v1/tickers/like`;
type IType = {
  type: string,
  onSubmit: Function,
};

const TickersSelect = ({ type, onSubmit }: IType) => {
  const userDetails = useAuthState();
  const [matchedTickersToQuery, setMatchedTickersToQuery] = useState([]);
  const [selectedTickers, setSelectedTickers] = useState(new Set());

  const onTickerChanged = (value) => {
    fetchTickersLike(value);
  };

  const onToggleTicker = (value) => {
    const newSet = new Set(selectedTickers);
    if (newSet.has(value)) {
      newSet.delete(value);
    } else {
      newSet.add(value);
    }
    setSelectedTickers(newSet);
  };

  const listItemMoreClasses = (ticker) => {
    if (selectedTickers.has(ticker)) {
      return css.tickersSelectList_item__selected;
    } else {
      return "";
    }
  };

  const fetchTickersLike = (like) => {
    like = like.trim();
    if (like === "") {
      return [];
    }

    const url = `${TICKERS_LIKE_URL}/${like}?&user_email=${userDetails.user}&user_token=${userDetails.token}`;
    fetch(url)
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw new Error("Fetched with error.");
      })
      .then((data) => {
        setMatchedTickersToQuery(data);
      })
      .catch(() => {
        setMatchedTickersToQuery([]);
        console.log("Could not fetch tickers");
      });
  };

  const renderMatched = () => {
    let renderedArray = [];
    renderedArray = matchedTickersToQuery?.map((data) => {
      const ticker = data[0];
      return (
        <div
          key={ticker}
          className={`${css.tickersSelectList_item} ${listItemMoreClasses(
            ticker
          )}`}
          onClick={(e) => onToggleTicker(ticker)}
        >
          {ticker}
          <hr className={css.tickersSelectList_hr} />
        </div>
      );
    });
    return renderedArray;
  };

  return (
    <>
      <Input
        type="text"
        placeholder="Ticker"
        onChange={(e) => onTickerChanged(e.target.value)}
      />

      <div>
        <Button addClasses='danger' onClick={(e) => onSubmit(selectedTickers)}>
          {type}
        </Button>
      </div>
      <div className={css.tickersSelectList}>{renderMatched()}</div>
    </>
  );
};

export default TickersSelect;
