import React, { useContext, useState, useCallback } from "react";

import Button from "Components/Button";
import TickersSelect from "Components/TickersSelect";
import css from "./ManageTickersModal.module.scss";

import Modal from "react-modal";

const customStyles = {
  content: {
    top: "0",
    left: "0",
    right: "0",
    bottom: "0",
  },
  overlay: { zIndex: 9999 },
};

type IType = {
  type: string,
  onSubmit: Function,
  onClose: Function,
  +isOpen: boolean,
};

const ManageTickersModal = ({ type, onSubmit, onClose, isOpen = false }: IType) => {
  const [modalIsOpen, setIsOpen] = useState(isOpen);

  const openModal = () => {
    setIsOpen(true);
  };

  const handleCloseModal = () => {
    setIsOpen(false);
    onClose();
  };

  const handleSubmit = (selectedTickers: Array<string>) => {
    setIsOpen(false);
    onSubmit(selectedTickers);
  };

  return (
    <Modal
      isOpen={modalIsOpen}
      onAfterOpen={() => {}}
      onRequestClose={handleCloseModal}
      style={customStyles}
      contentLabel="Example Modal"
    >
      <TickersSelect type={type} onSubmit={handleSubmit}/>

      <div className="modal-body">
        <div className={css.closeBtn} onClick={handleCloseModal}>
          &#x2715;
        </div>
      </div>
    </Modal>
  );
};

export default ManageTickersModal;
