import React, { useState, useEffect, useContext } from "react";
import { last, keys } from "lodash";

import apiV1 from "Api/v1";

import { List } from "Components/List";
import Button from "Components/Button";
import { useAuthState } from "Context/User";
import { ManageTickersModal } from "Components/Modal";

import css from "./PageLayout.module.scss";


export function Portfolio() {
  const userDetails = useAuthState();
  const [portfolioData, setPortfolioData] = useState([]);
  const [numUpdates, setNumUpdates] = useState(0);
  const [manageTickersType, setModalManageTickersType] = useState('');

  const portfolioDataToList = (portfolioData) => {
    return keys(portfolioData)
      .sort()
      .map((key) => {
        const item = portfolioData[key];
        const lastPrice = last(item.data) ? last(item.data)[1] : 0;
        return {
          ticker: key,
          companyName: item.symbol.company_name,
          price: lastPrice,
          data: item.data,
        };
      });
  };

  async function getPortfolioData() {
    const response = await apiV1.getPortfolio(userDetails);
    if (response?.status === 200) {
      const portfolioList = portfolioDataToList(response.data);
      setPortfolioData(portfolioList);
    }
  }

  useEffect(() => {
    getPortfolioData();
  }, [numUpdates, userDetails]);

  const PortfolioActions = () => {
    const handleClose = () => {
      setModalManageTickersType('');
    };

    // useCallback with [manageTickersType] ?? <-- here we have always the 'add'
    const handleAddTickers = async (tickers: $ReadOnlyArray<string>) => {
      const response = await apiV1.saveTickers(tickers, userDetails, manageTickersType);
      setNumUpdates(numUpdates+1);
      handleClose();
    };

    return (
      <div className={css.controls}>
        <Button onClick={() => setModalManageTickersType('add')}>Add</Button>
        <Button addClasses='danger' onClick={() => setModalManageTickersType('remove')}>Remove</Button>
        {manageTickersType && (
          <ManageTickersModal
            type={manageTickersType}
            onSubmit={handleAddTickers}
            onClose={handleClose}
            isOpen={true}
          />
        )}
      </div>
    );
  };

  return (
    <div className={css.content}>
      <h2 className={css.title}>Portfolio</h2>
      <PortfolioActions />

      <List items={portfolioData} />
    </div>
  );
}
