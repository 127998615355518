import React from 'react';

import css from './Input.module.scss';

const Input = ({ type, addClasses, onChange, placeholder, children }) => {
  addClasses = addClasses?.split(' ').map((clsName) => css[clsName]).join(' ') || '';
  
  return <input placeholder={placeholder} type={type} onChange={onChange} className={`${css.button} ${addClasses}`}>{children}</input>;
};

export default Input;
